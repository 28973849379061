/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpHandoffStates($stateProvider) {
  'ngInject';
  $stateProvider
    .state('handoff', {
      url: '/handoff?referral',
      templateUrl: 'apps/handoff/templates/acp-handoff.ng.html',
      controller: 'acpHandoffSectionCtrl',
      controllerAs: 'vm',
      params: {
        referral: null
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';
          return import(
            /* webpackChunkName: "acp.apps.handoff" */ './index'
          ).then($ocLazyLoad.loadModule);
        },
        accountFingerprint: (module, $location) => {
          'ngInject';
          return $location.hash();
        }
      }
    })
    .state('handoff-activate', {
      url: '/handoff-activate',
      params: {
        access_token: null,
        card_id: null,
        activation_block: null,
        registration_blocks: [],
        account_fingerprint: null
      },
      resolve: {
        $stateRedirect(nsStorage, $state, $stateParams, $location) {
          if ($location.hash()) {
            const handoffData = JSON.parse(atob($location.hash()));
            nsStorage.session('handoffData', handoffData);
          } else {
            nsStorage.session('handoffData', $stateParams);
          }
          $state.go('activate-handoff');
          return;
        }
      }
    })
    .state('handoff-register', {
      params: {
        access_token: null,
        registration_blocks: [],
        account_fingerprint: null
      },
      resolve: {
        $stateRedirect(nsStorage, $state, $stateParams) {
          nsStorage.session('handoffData', $stateParams);
          $state.go('activate-handoff');
          return;
        }
      }
    })
    .state('handoff-cip-document-validation', {
      params: {
        access_token: null,
        registration_blocks: [],
        account_fingerprint: null,
        referralCode: null
      },
      resolve: {
        $stateRedirect(nsStorage, $state, $stateParams, webapiResource) {
          nsStorage.session('handoffData', $stateParams);
          webapiResource.setToken($stateParams.access_token);
          /**
           * Instead of state.go used window.history.replace state so that
           * if Secure Upload mfe is visible it should go to its MFE else
           * Angular feature.
           */
          window.history.replaceState(null, '', '/account/secure-upload');
          return;
        }
      }
    })
    .state('handoff-fraud-detection', {
      params: {
        access_token: null,
        registration_blocks: [],
        account_fingerprint: null,
        purpose: null,
        transaction_type: null
      },
      resolve: {
        $stateRedirect(nsStorage, $state, $stateParams, webapiResource) {
          nsStorage.session('handoffData', $stateParams);
          webapiResource.setToken($stateParams.access_token);
          /**
           * Instead of state.go used window.history.replace state so that
           * if Handoff MFE is visible it should go to Fraud Detection MFE
           * else redirect to Angular Fraud Detection feature.
           */
          window.history.replaceState(null, '', '/account/fraud-detection');
          return;
        }
      }
    });
}
